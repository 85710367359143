import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },

  {
    path: 'sesion',
    loadChildren: () => import('./sesion/sesion.module').then( m => m.SesionPageModule)
  },

  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

  {
    path: 'mapagral',
    loadChildren: () => import('./mapagral/mapagral.module').then( m => m.MapagralPageModule)
  },

  {
    path: 'explora',
    loadChildren: () => import('./explora/explora.module').then( m => m.ExploraPageModule)
  },

  {
    path: 'conoce',
    loadChildren: () => import('./conoce/conoce.module').then( m => m.ConocePageModule)
  },

  {
    path: 'protege',
    loadChildren: () => import('./protege/protege.module').then( m => m.ProtegePageModule)
  },

  {
    path: 'reporta',
    loadChildren: () => import('./reporta/reporta.module').then( m => m.ReportaPageModule)
  },

  {
    path: 'noticias',
    loadChildren: () => import('./noticias/noticias.module').then( m => m.NoticiasPageModule)
  },

  {
    path: 'acerca',
    loadChildren: () => import('./acerca/acerca.module').then( m => m.AcercaPageModule)
  },

  {
    path: 'comunidad',
    loadChildren: () => import('./comunidad/comunidad.module').then( m => m.ComunidadPageModule)
  },
  {
    path: 'descubre',
    loadChildren: () => import('./descubre/descubre.module').then( m => m.DescubrePageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  
  {
    path: 'agua',
    loadChildren: () => import('./desc/agua/agua.module').then( m => m.AguaPageModule)
  },
  {
    path: 'clima',
    loadChildren: () => import('./desc/clima/clima.module').then( m => m.ClimaPageModule)
  },
  {
    path: 'riesgos',
    loadChildren: () => import('./desc/riesgos/riesgos.module').then( m => m.RiesgosPageModule)
  },
  {
    path: 'turismo',
    loadChildren: () => import('./desc/turismo/turismo.module').then( m => m.TurismoPageModule)
  },
  
  {
    path: 'geoysuelos',
    loadChildren: () => import('./desc/geoysuelos/geoysuelos.module').then( m => m.GeoysuelosPageModule)
  },
  {
    path: 'biodiv',
    loadChildren: () => import('./desc/biodiv/biodiv.module').then( m => m.BiodivPageModule)
  },
  {
    path: 'mapabiodiv',
    loadChildren: () => import('./desc/mapabiodiv/mapabiodiv.module').then( m => m.MapabiodivPageModule)
  },
  {
    path: 'mapaagua',
    loadChildren: () => import('./desc/mapaagua/mapaagua.module').then( m => m.MapaaguaPageModule)
  },
  {
    path: 'mapaclima',
    loadChildren: () => import('./desc/mapaclima/mapaclima.module').then( m => m.MapaclimaPageModule)
  },
  {
    path: 'mapasuelo',
    loadChildren: () => import('./desc/mapasuelo/mapasuelo.module').then( m => m.MapasueloPageModule)
  },
  {
    path: 'mapariesgos',
    loadChildren: () => import('./desc/mapariesgos/mapariesgos.module').then( m => m.MapariesgosPageModule)
  },
  {
    path: 'mapaturismo',
    loadChildren: () => import('./desc/mapaturismo/mapaturismo.module').then( m => m.MapaturismoPageModule)
  },
  {
    path: 'mapareportes',
    loadChildren: () => import('./mapareportes/mapareportes.module').then( m => m.MapareportesPageModule)
  },
  {
    path: 'mapaeventos',
    loadChildren: () => import('./mapaeventos/mapaeventos.module').then( m => m.MapaeventosPageModule)
  },
  {
    path: 'mapaactores',
    loadChildren: () => import('./mapaactores/mapaactores.module').then( m => m.MapaactoresPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
